import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <StaticImage
        alt="hero img"
        src="../images/sebastian-unrau-sp-p7uuT0tw-unsplash.jpg"
      />
      <p>トップページ</p>
    </Layout>
  );
};

export default IndexPage;
